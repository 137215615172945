







































































































.form-group {
  position: relative;
}

.list-templates {
  .list-item {
    width: 33.333%;
    display: inline-block;
    padding: 5px;
    cursor: pointer;
    &.active {
      img {
        border: 2px solid #4284f4;
        box-shadow: 0px 0px 3px #4284f4;
      }
    }
    img {
      width: 100%;
      border-radius: 5px;
      padding: 5px;
      border: 1px solid #ccc;
    }
  }
}
